<template>
    <div class="app-switch" :class="{ disabled }">
        <h5 class="heading label">
            <slot></slot>
        </h5>

        <input type="checkbox"
            :checked="value"
            :disabled="disabled"

            @input="onChange"
            @change="onChange"
        >

        <span class="label" v-if="label">{{ label }}</span>
    </div>
</template>

<script>
export default {
    props: {
        value: { required: true },

        disabled: { type: Boolean, default: false },

        label: { type: String, default: '' },
    },

    methods: {
        onChange(event) {
            this.$emit('input', event.target.checked)

            if (event.target.checked != this.value) {
                this.$emit('change', event.target.checked)
            }
        },
    },
}
</script>

<style lang="scss">
.app-switch {
    display: flex;
    align-items: center;

    input[type=checkbox] {
        border: none;
        appearance: none;
        position: relative;
        flex-shrink: 0;
        margin: 0;
        width: 35px;
        height: 22px;
        background-color: #ccc;
        border-radius: 11px;
        cursor: pointer;

        &::after {
            position: absolute;
            top: 1px;
            left: 1px;
            display: block;
            width: 20px;
            height: 20px;
            content: '';
            background-color: #fff;
            border: 1px solid #b4b3b3;
            border-radius: 50%;
        }

        &:checked {
            background-color: var(--color-btn-primary-bg);

            &::after {
                left: 35px - 1px - 20px;
                border-color: var(--color-btn-primary-bg-hover);
            }
        }
    }

    .label {
        font-size: 20px;
        line-height: 24px;
        margin: 0 16px;

        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }

        &:empty {
            display: none;
        }
    }

    &.disabled {
        opacity: .4;

        input[type=checkbox] {
            cursor: default;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-switch {
        .label {
            font-size: 18px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-switch {
        .label {
            font-size: 16px;
        }
    }
}
</style>