<template>
    <app-dialog-info
        class="app-dialog-disable-mfa"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Disable sign in with OTP</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

            <div class="form-group">
                <div class="form-controls">
                    <app-input
                        v-focus

                        v-model="MFACode"

                        :error="errors.fields.MFACode"
                        :disabled="loading"

                        @change="errors.fields.MFACode = null"
                    />
                </div>
            </div>

            <div class="actions">
                <button class="btn btn-primary" @click="confirm">Send</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import UserDetailsValidator from '@/validators/user-details-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true,                        },
        maxWidth: { type: [Number, String], default: 610   },
        member:   { type: Object,           required: true },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            MFACode: null,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        validation() {
            let is_valid = true

            this.errors.fields = {}

            const values = {
                MFACode: {
                    value: this.MFACode,
                    message: 'Please, enter the code you received via app',
                },
            }

            for (const key in values) {
                if (UserDetailsValidator.isRuleExists(key)) {
                    if (UserDetailsValidator.isInvalid(key, values[key].value, values[key].message)) {
                        this.errors.fields[ key ] = UserDetailsValidator.getError()

                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    IdentityProvider: 'LOCAL',
                    AuthID: this.member.UUID,
                    MFAType: 'TOTP',
                    MFACode: this.MFACode,
                }

                this.$store.dispatch('api_auth/RemoveMultiFactorOnIdentity', params)
                    .then(() => {
                        this.$emit('confirm')

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: 'OTP Sign in is disabled. You can enable it for your account anytime in the members settings',
                            delay: 5000,
                        })
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.$emit('close')
                }
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-disable-mfa {
    .app-dialog-body {
        padding: 80px 36px;

        p {
            margin-bottom: 30px;

            font-weight: normal;
        }

        .form-group {
            width: 100%;

            margin-bottom: 30px;
        }

        .actions {
            display: flex;
            justify-content: center;

            width: 100%;

            .btn {
                max-width: 210px;
            }
        }
    }
}
</style>