<template>
    <div class="app-placeholder">{{ message }}</div>
</template>

<script>
export default {
    props: {
        message: { type: String, required: true },
    },
}
</script>

<style lang="scss">
.app-placeholder {
    width: 100%;
    padding: 92px 24px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    background-color: var(--color-component-bg-primary);
    border-radius: $border-radius-secondary;
    box-shadow: var(--box-shadow-primary);
}
</style>