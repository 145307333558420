<template>
    <div class="app-invited-members">
        <app-loader v-if="loading"></app-loader>

        <ul>
            <li v-for="(member, key) in members" :key="key">
                <span>{{ member.Email }}</span>
                <button class="btn btn-checked" :class="{ invited: member.UUID in invited }" @click="toggle(member)"></button>
            </li>
        </ul>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'

export default {
    props: {
        value:    { type: Array, required: true },
        uuid:     { required: true },

        filter:   { default: '' },

        maxWidth: { type: [ Number, String ], default: 610 },
    },

    components: {
        appLoader,
    },

    data() {
        return {
            members: [],

            filter_previous: '',

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getMembers()
        },
        
        getMembers(page) {
            // console.group('getMembers(page) {')
            // console.log('page', page)

            this.loading = true

            // console.warn('filter', {...filter})

            let params = {
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
            }

            const word = this.filter.trim()
            // console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': this.filter !== this.filter_previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter_previous = this.filter
            }
            // console.log('params', {...params})

            const with_reset = params['SearchOptions.PageNumber'] < 2

            this.$store.dispatch('getMembersOutOfTeam', { team_uuid: this.uuid, params }).then(response => {
                const members_list = response.Members

                if (with_reset) {
                    this.members = members_list
                } else {
                    for (let i = 0, len = members_list.length; i < len; i++) {
                        this.members.push( members_list[i] )
                    }
                }

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.members = []

                this.loading = false
            })

            // console.groupEnd()
        },

        toggle(member) {
            if (member.UUID in this.invited) {
                const index = this.value.findIndex(uuid => uuid == member.UUID)
                
                this.value.splice(index, 1)
            } else {
                this.value.push(member.UUID)
            }

            this.$emit('input', this.value)
            this.$emit('change', this.value)
        },
    },

    watch: {
        filter() {
            if (this.filter.trim() != this.filter_previous.trim()) {
                this.getMembers(1)
            }
        },
    },

    computed: {
        invited() {
            let invited = {}

            for (let i = 0, len = this.value.length; i < len; i++) {
                invited[ this.value[i] ] = true
            }

            return invited
        },
    },
}
</script>

<style lang="scss">
.app-invited-members {
    position: relative;
    padding: 12px 8px;
    border: 1px solid var(--color-input-border);
    background: var(--color-input-bg);
    border-radius: $border-radius-primary;

    .app-loader {
        svg {
            width: 48px;
            height: 48px;
        }
    }

    ul {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        padding: 0 12px;

        @include webkit-scrollbar-vertical(300px, var(--color-component-bg-primary), var(--color-scrollbar-thumb-bg));

        li {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 22px;

            &:last-child { margin: 0; }

            span {
                flex-grow: 1;
                @include text-overflow();
            }

            .btn {
                flex-shrink: 0;
                margin-left: 4px;

                &.btn-checked {
                    width: 24px;
                    height: 24px;

                    color: var(--color-icon-action);

                    @include icon-before($icon-circle-plus);

                    &.invited {
                        color: var(--color-icon-disabled);

                        @include icon-before($icon-circle-checkmark);
                    }

                    &:hover {
                        opacity: .6;
                    }

                    &:active {
                        opacity: .8;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-invited-members {
        ul {
            font-size: 14px;
            line-height: 20px;
            padding: 0 12px 0 0;
            
            max-height: 215px;

            li {
                margin-bottom: 12px;
            }
        }
    }
}
</style>