import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    firstname: {
        name: 'First name',
        required: true,
        format: /^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
        custom_message: 'Sorry, that\'s not a valid first name. Check the details and try again.',
    },

    lastname: {
        name: 'Last name',
        required: true,
        format: /^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
        custom_message: 'Sorry, that\'s not a valid last name. Check the details and try again.',
    },

    fullname: {
        name: 'Full name',
        required: true,
        format: /^(?=.{1,32}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
        custom_message: 'Sorry, that\'s not a valid full name. Check the details and try again.',
    },

    email: {
        name: 'Email address',
        required: true,
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        custom_message: 'Sorry, that\'s not a valid email address. Check the details and try again.',
    },

    mobile: {
        name: 'Mobile number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,   // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                              // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                    //     на value
            },
        ],
        format: /^\+?\d{9,12}$/,                                // после всех предпроверок и замен - value должно соответсвовать format
        custom_message: 'Sorry, that\'s not a valid mobile number. Check the details and try again.',
    },

    phone: {
        name: 'Phone number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,   // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                              // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                    //     на value
            },
        ],
        format: /^\+?\d{9,12}$/,                                // Section 3.2.2, https://www.nad.org.nz/assets/ResourceLinks/NAD-Rules-v7.0.pdf
        custom_message: 'Sorry, that\'s not a valid phone number. Check the details and try again.',
    },

    extref1: {
        name: 'External Ref 1',
        replaces: [
            {
                format: /^.*$/,
                pattern: /^[ ]+/g,
                replace: '',
            },
        ],
        format: /^.+$/,
        required: true,
    },

    code: {
        name: 'SMS code',
        required: true,
    },

    login: {
        name: 'Username',
        required: true,
    },

    pass: {
        name: 'Password',
        required: true,
    },

    passnew: {
        name: 'Password',
        required: true,
        customPasswordCheck: true,
    },

    devicename: {
        name: 'Device name',
        required: true,
    },

    MFACode: {
        name: 'MFA code',
        required: true,
    },
})