<template>
    <div id="page-team">
        <base-layout>
            <template v-slot:sidebar>
                <app-sidebar-details title="Member details" @close-sidebar="closeSidebar">
                    <member-details
                        :auth-spid="auth_spid"
                        :auth-uuid="auth_uuid"
                        :auth-id="auth_id"
                        :auth-provider="auth_provider"

                        @saved="getTeamMembers"
                        @close-sidebar="closeSidebar"
                    />
                </app-sidebar-details>
            </template>

            <app-loader v-if="is_loading" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-back-to" :to="back_to">Back to Teams</router-link>

                        <h1 class="heading">Team: {{ team.Name }}</h1>

                        <p class="description">{{ team.Description }}</p>
                    </div>
                </div>

                <div class="row">
                    <!-- <div class="col-8 col-tab-12"> -->
                    <div class="col-12 col-tab-12">
                        <template v-if="is_loaded">
                            <template v-if="have_members">
                                <app-table
                                    class="members-table"

                                    title="Members"

                                    :clickable="true"
                                    :selected-row="member_uuid"

                                    :cols="cols"
                                    :rows="members"

                                    @row-click="onMembersRowClick"
                                    @row-click-mobile="openMemberDetails"
                                />

                                <app-pagination
                                    v-if="pagination.total"

                                    v-model="pagination.page"

                                    :total="pagination.total"
                                    :limit="pagination.limit"
                                    :page-range="pagination.range"

                                    @change="onPageChange"
                                />
                            </template>

                            <app-placeholder v-else message="There are no members in this team" />
                        </template>
                    </div>

                    <!--
                        <div class="col-4 col-tab-12">
                            <app-adding-banner
                                header="Invite a member"
                                description="Invite a member to this team"

                                image="team-member"

                                action="invite"

                                @invite="dialogs.invite.show = true"
                            />
                        </div>
                    -->
                </div>
            </div>
        </base-layout>

        <dialog-add-team-member
            v-if="dialogs.invite.show"
            v-model="dialogs.invite.show"

            :uuid="uuid"
            :name="team.Name"
        ></dialog-add-team-member>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import memberDetails from '@/views/member'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appAddingBanner from '@/components/app-adding-banner'
import appPlaceholder from '@/components/app-placeholder'

import dialogAddTeamMember from './dialogs/dialog-add-team-member'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })}
    },
    
    components: {
        baseLayout,
        appSidebarDetails,

        memberDetails,

        appLoader,
        appTable,
        appPagination,
        appAddingBanner,
        appPlaceholder,

        dialogAddTeamMember,
    },

    data() {
        return {
            team: {},
            members: [],

            filter: {
                word: '',
                previous: '',
            },

            cols: [
                { key: 'email',  title: 'Email', highlight: true, },
                { key: 'authid', title: 'AuthID'                  },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            member_uuid: null,
            auth_uuid: null,
            auth_spid: null,
            auth_id: null,
            auth_provider: null,

            dialogs: {
                invite: {
                    show: false,
                },
            },

            loading: {
                team:    false,
                members: false,
            },

            loaded: {
                team:    false,
                members: false,
            },

            referrer: null,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.loadTeamInfo()
        },

        loadTeamInfo() {
            this.loading.team = true

            this.$store.dispatch('getTeamByUUID', { uuid: this.uuid })
                .then(team => {
                    this.team = team

                    this.getTeamMembers()

                    this.loading.team = false

                    this.loaded.team = true
                })
                .catch(error => {
                    this.loading.team = false

                    this.loaded.team = true

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.$router.push({
                        ...this.back_to,
                        replace: true,
                    })
                })
        },

        formatAuthID(thismember) {
            if (!thismember) {
                return ''
            }
            if (!thismember.AuthID && !thismember.IdentityProvider) {
                return ''
            }
            if (thismember.AuthID && thismember.IdentityProvider) {
                return `${thismember.IdentityProvider}/${thismember.AuthID}`
            }
            if (thismember.AuthID) {
                return thismember.AuthID
            } else {
                return thismember.IdentityProvider
            }
        },

        getTeamMembers(page, filter) {
            this.loading.members = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }

            let params = {
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
            }

            const word = filter.word.trim()

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }

            this.$store.dispatch('api_auth/FindAuthRoleGroupMembersPaginated', { SPID: this.current_spid, AuthRoleGroupUUID: this.uuid, ...params })
                .then(response => {
                    const mappingMembers = () => {
                        this.members = response.AuthRoleGroupMembers.map(member => ({
                            uuid: member.UUID,
                            UUID: member.UUID,
                            auth_spid: member.SPID,
                            auth_id: member.AuthID,
                            auth_provider: member.IdentityProvider,

                            name: member.DisplayName ? member.DisplayName : member.AuthID,

                            email: member.DisplayName ? member.DisplayName : member.AuthID,

                            authid: this.formatAuthID(member),
                        }))

                        this.pagination.page = response.PageInfo.PageNumber
                        this.pagination.total = response.PageInfo.TotalItemCount

                        if (this.$route.params.page != this.pagination.page
                            && !(
                                this.$route.params.page === undefined
                                && this.pagination.page === 1
                            )
                        ) {
                            this.$router.push({ name: this.$route.name, params: { uuid: this.uuid, page: this.pagination.page }, replace: true })
                        }

                        this.loading.members = false

                        this.loaded.members = true
                    }

                    this.$store.dispatch('getTeamsRoles').then(mappingMembers).catch(mappingMembers)
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.members = []

                    this.loading.members = false

                    this.loaded.members = true
                })
        },

        fullname(firstname, lastname) {
            const fullname = [firstname, lastname].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        memberRole(member) {
            let role = '-'

            const team = member.Teams.find(team => team.UUID == this.uuid)

            if (team && team.Role in this.teams_roles) {
                role = this.teams_roles[ team.Role ].Title
            }

            return role
        },


        /**
         * Filtering
         */
        onSearch(search) {
            if (search.word.trim() != this.filter.word.trim()) {
                this.getTeamMembers(this.pagination.page, { word: search.word })
            }
        },

        onPageChange(page) {
            this.getTeamMembers(page)
        },

        openMemberDetails(member) {
            this.$router.push({ name: 'member', params: { auth_id: member.auth_id } })
        },

        onMembersRowClick(member) {
            if (this.member_uuid == member.uuid && this.auth_id == member.auth_id) {
                this.closeSidebar()
            } else {
                this.member_uuid = member.uuid
                this.auth_spid = member.auth_spid
                this.auth_uuid = member.auth_id ? member.auth_id : member.uuid
                this.auth_provider = member.auth_provider
                this.auth_id = member.auth_id

                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.member_uuid = null
            this.auth_id = null

            this.$emit('close-sidebar')
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'teams_roles',
        ]),

        uuid() {
            return this.$route.params.uuid
        },

        have_members() {
            return this.members.length > 0
        },

        is_loading() {
            let is_loading = false

            for (const key in this.loading) {
                if (this.loading[key]) {
                    is_loading = true

                    break
                }
            }

            return is_loading
        },

        is_loaded() {
            let is_loaded = true

            for (const key in this.loaded) {
                is_loaded = is_loaded && this.loaded[key]

                if (!is_loaded) {
                    break
                }
            }

            return is_loaded
        },

        back_to() {
            return this.referrer && this.referrer.name == 'teams'
                ? this.referrer
                : { name: 'teams' }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
#page-team {
    margin-top: 24px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            @include text-overflow();
        }
    }

    .description {
        font-size: 18px;
        line-height: 24px;
        margin-top: 40px;

        &:empty {
            display: none;
        }
    }

    .members-table {
        margin-top: 30px;

        @include table-cols-width((150px, 200px, 100px, 75px), true);
    }

    .app-adding-banner {
        margin-top: 30px;
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    #page-team {
        .members-table {
            @include table-cols-width((150px, 200px, 100px, 60px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    #page-team {
        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .description {
            font-size: 16px;
            margin-top: 15px;
        }

        .members-table {
            margin-top: 15px;

            @include table-cols-width-mobile((75px, 200px), true);
        }

        .app-adding-banner {
            margin-top: 15px;
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>