<template>
    <app-dialog class="dialog-add-team-member"
        v-if="show"
        v-model="show"
        
        :max-width="maxWidth"

        :loading="loading"
        :processing="processing"

        @closes="closes"
    >
        <template #head>
            <h3>Invite a member</h3>
        </template>

        <template #form>
            <app-input
                v-model.trim="filter"
                label="E-mail Address"

                :error="errors.filter"

                @change="errors.filter = null"

            ></app-input>
            <!-- <app-invited-members v-model="members" :filter="filter" :uuid="uuid"></app-invited-members> -->
        </template>

        <template #btns>
            <button class="btn btn-primary" @click.prevent="confirm" :disabled="processing">Add</button>
            <button class="btn btn-cancel"  @click.prevent="close" :disabled="processing">Cancel</button>
        </template>
    </app-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'

import appInput from '@/components/app-input'
import appInvitedMembers from '@/views/team/components/app-invited-members'

import UserDetailsValidator from '@/validators/user-details-validator'

export default {
    props: {
        value:    { required: true },
        uuid:     { required: true },
        name:     { required: true },

        maxWidth: { type: [ Number, String ], default: 610 },
    },

    components: {
        appDialog,

        appInput,
        appInvitedMembers,
    },

    data() {
        return {
            filter: '',
            members: [],

            errors: {
                filter: null,
            },

            loading: false,
            processing: false,
        }
    },

    methods: {
        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        confirm() {
            if (UserDetailsValidator.isInvalid('email', this.filter, 'Please input valid email address')) {
                this.errors.filter = UserDetailsValidator.getError()
            } else {
                this.processing = true
                this.errors.filter = null

                console.warn('invited members:', this.members)

                setTimeout(() => {
                    this.processing = false
                    this.close()
                }, 500)
            }
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-add-team-member {
    z-index: $z-index-dialog-upmost;

    .app-dialog-container {
        flex-shrink: 0;
        height: auto;
        max-height: none;

        .app-dialog-body {
            padding: 48px 80px;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.dialog-add-team-member {
        .app-dialog-container {
            .app-dialog-body {
                padding: 64px 24px;
            }
        }
    }
}
</style>
