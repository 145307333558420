<template>
    <div id="page-member" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="is_loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="back_to.route" v-if="!on_sidebar">Back to {{ back_to.title }}</router-link>

                    <div class="header-info">
                        <h2 class="heading">{{ fullname }}</h2>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <div class="board personal">
                        <h3 class="heading">Personal information</h3>

                        <app-input
                            v-model="member.Username"

                            label="Username"

                            :disabled="true"
                        />

                        <app-input
                            v-model="member.FirstName"

                            label="First name"

                            :error="errors.member.fields.FirstName"

                            @change="errors.member.fields.FirstName = null"
                        />

                        <app-input
                            v-model="member.LastName"

                            label="Last name"

                            :error="errors.member.fields.LastName"

                            @change="errors.member.fields.LastName = null"
                        />

                        <app-input
                            v-model="member.MobileNumber"

                            label="Mobile number"

                            :error="errors.member.fields.MobileNumber"

                            @change="errors.member.fields.MobileNumber = null"
                        />

                        <app-input
                            v-model="member.Email"

                            label="Email"

                            :error="errors.member.fields.Email"

                            @change="errors.member.fields.Email = null"
                        />

                        <app-switch
                            v-if="show_mfa_auth_toggler"

                            v-model="sign_in_with_otp"

                            label="Sign in with OTP"

                            @change="toggleMFA"
                        />

                        <button class="btn btn-primary btn-change-password" v-if="is_super_admin || is_sp_admin" @click="showChangePasswordDialog">Change password</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <div class="board teams">
                        <h3 class="heading">Team membership</h3>

                        <ul v-if="is_member_teams_available">
                            <li v-for="(team, key) in member_teams" :key="key">
                                <span class="team-name">{{ team.Name }}</span>

                                <div class="actions">
                                    <button
                                        v-if="show_remove_team_button"
                                        class="btn btn-table-action action-secondary action-remove"
                                        @click="removeTeam(team)"
                                    ></button>
                                </div>
                            </li>
                        </ul>

                        <form v-if="is_teams_for_adding_available" @submit.prevent="addTeam">
                            <app-dropdown-select
                                v-model="team_uuid"

                                label="Select team"

                                :options="teams_for_adding"

                                key-value="UUID"
                                key-title="Name"
                            />

                            <button class="btn btn-primary" type="submit" :disabled="!team_uuid">Add</button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <app-error v-model="errors.save"></app-error>

                    <button class="btn btn-primary btn-full-width" :disabled="!is_changed" @click="save">Save</button>
                </div>
            </div>
        </div>

        <app-dialog-change-password
            v-if="dialogs.change_password.show"
            v-model="dialogs.change_password.show"

            :member="original_member"
        />

        <app-dialog-enable-mfa
            v-if="dialogs.enable_mfa.show"
            v-model="dialogs.enable_mfa.show"

            :member="original_member"

            @confirm="onDialogEnableMFAConfirm"
            @close="onDialogEnableMFAClose"
        />

        <app-dialog-disable-mfa
            v-if="dialogs.disable_mfa.show"
            v-model="dialogs.disable_mfa.show"

            :member="original_member"

            @confirm="onDialogDisableMFAConfirm"
            @close="onDialogDisableMFAClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appSwitch from '@/components/app-switch'
import appDropdownSelect from '@/components/app-dropdown-select'

import appDialogChangePassword from './components/app-dialog-change-password'
import appDialogEnableMfa from './components/app-dialog-enable-mfa'
import appDialogDisableMfa from './components/app-dialog-disable-mfa'

import UserDetailsValidator from '@/validators/user-details-validator'

import errMessage from '@/helpers/errMessage'

const EMPTY_MEMBER = {
    FirstName: '',
    LastName: '',
    MobileNumber: '',
    Email: '',
}

export default {
    props: {
        authSpid:     { type: Number, default: 0    },
        authUuid:     { type: String, default: null },
        authId:       { type: String, default: null },
        authProvider: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appSwitch,
        appDropdownSelect,

        appDialogChangePassword,
        appDialogEnableMfa,
        appDialogDisableMfa,
    },

    data() {
        return {
            member: JSON.parse(JSON.stringify(EMPTY_MEMBER)),
            original_member: JSON.parse(JSON.stringify(EMPTY_MEMBER)),

            member_teams: {},
            original_member_teams: {},

            team_uuid: null,

            sign_in_with_otp: false,

            loading: {
                member: false,
                member_teams: false,
                teams: false,
                save: false,
            },

            dialogs: {
                change_password: {
                    show: false,
                },

                enable_mfa: {
                    show: false,
                },

                disable_mfa: {
                    show: false,
                },
            },

            errors: {
                member: {
                    fields: {},
                },

                save: null,
            },

            referrer: null,
            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.member_auth_id) {
                this.getMemberInfo()
            }
        },

        getMemberInfo() {
            this.getTeams()
            this.getLocalUser()
            this.getMemberTeams()
        },

        getLocalUser() {
            this.loading.member = true

            this.sign_in_with_otp = this.is_mfa_used

            const params = {
                SPID: this.authSpid || this.current_spid,
                UUID: this.authUuid || this.member_auth_id,
            }

            let dispatchEndpoint = params.UUID ? 'api_auth/GetLocalUserByUUID' : 'api_auth/GetLocalUserBySPIDUsername'

            this.$store.dispatch(dispatchEndpoint, params)
                .then(response => {
                    this.member          = JSON.parse(JSON.stringify(response))
                    this.original_member = JSON.parse(JSON.stringify(response))

                    this.loading.member = false
                })
                .catch(error => {
                    this.loading.member = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to.route,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        getMemberTeams() {
            this.loading.member_teams = true

            const params = {
                SPID: this.current_spid,
                AuthID: this.member_auth_id,
            }

            this.$store.dispatch('api_auth/GetAuthRoleGroupMembersWithGroups', params)
                .then(response => {
                    const teams = response.AuthRoleGroupMembersWithGroups
                                && Array.isArray(response.AuthRoleGroupMembersWithGroups)
                                && response.AuthRoleGroupMembersWithGroups.length ? response.AuthRoleGroupMembersWithGroups[0].AuthRoleGroups : []

                    const member_teams = {}

                    for (const team of teams) {
                        console.log(team)
                        member_teams[team.UUID] = {
                            UUID: team.UUID,
                            Name: team.Name,
                            SPID: team.SPID,
                            AuthRoleGroupMemberUUID: team.AuthRoleGroupMemberUUID,
                        }
                    }

                    this.member_teams          = JSON.parse(JSON.stringify(member_teams))
                    this.original_member_teams = JSON.parse(JSON.stringify(member_teams))

                    this.loading.member_teams = false
                })
                .catch(error => {
                    this.loading.member_teams = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to.route,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        getTeams() {
            this.loading.teams = true

            const stopLoading = () => {
                this.loading.teams = false
            }

            this.$store.dispatch('getTeamsAll')
                .then(stopLoading)
                .catch(stopLoading)
        },

        addTeam() {
            this.$set(this.member_teams, this.team_uuid, {
                UUID: this.team_uuid,
                Name: this.teams[this.team_uuid].Name
            })

            this.team_uuid = null
        },

        removeTeam(team) {
            if (team.UUID in this.member_teams) {
                this.$delete(this.member_teams, team.UUID)
            }
        },

        validation() {
            let is_valid = true

            this.errors.member.fields = {}

            const fields = {
                FirstName:    { rule: 'firstname', message: 'Please, enter valid first name'    },
                LastName:     { rule: 'lastname',  message: 'Please, enter valid last name'     },
                MobileNumber: { rule: 'mobile',    message: 'Please, enter valid mobile number' },
                Email:        { rule: 'email',     message: 'Please, enter valid email'         },
            }

            for (const key in fields) {
                if (UserDetailsValidator.isRuleExists(fields[key].rule)) {
                    if (UserDetailsValidator.isInvalid(fields[key].rule, this.member[key], fields[key].message)) {
                        this.errors.member.fields[key] = UserDetailsValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        save() {
            if (this.validation()) {
                this.loading.save = true

                const promises = []

                if (this.is_member_changed) {
                    promises.push(this.saveMember())
                }

                if (Object.keys(this.teams_ready_to_be_added).length > 0) {
                    for (const key in this.teams_ready_to_be_added) {
                        promises.push(this.addMemberToTeam(this.teams_ready_to_be_added[key].UUID))
                    }
                }

                if (Object.keys(this.teams_ready_to_be_deleted).length > 0) {
                    for (const key in this.teams_ready_to_be_deleted) {
                        promises.push(this.removeMemberFromTeam(this.teams_ready_to_be_deleted[key]))
                    }
                }

                Promise.all(promises)
                    .then(() => {
                        this.loading.save = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to.route,
                                replace: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)

                        this.loading.save = false
                    })
            }
        },

        saveMember() {
            const params = {
                SPID: this.current_spid,
                UUID: this.member.UUID,
            }

            for (const key in EMPTY_MEMBER) {
                params[key] = this.member[key]
            }

            return this.$store.dispatch('api_auth/UpdateLocalUser', params)
        },

        addMemberToTeam(AuthRoleGroupUUID) {
            const params = {
                SPID: this.current_spid,
                AuthRoleGroupUUID,
                IdentityProvider: this.identity_provider,
                AuthID: this.authId,
            }

            return this.$store.dispatch('api_auth/AddAuthRoleGroupMember', params)
        },

        removeMemberFromTeam(groupMembershipSummary) {
            console.log('groupMembershipSummary', groupMembershipSummary)
            const params = {
                SPID: groupMembershipSummary.SPID,
                AuthRoleGroupUUID: groupMembershipSummary.UUID,
                UUID: groupMembershipSummary.AuthRoleGroupMemberUUID,
            }
    // console.log('payload', params)
// return
            return this.$store.dispatch('api_auth/DeleteAuthRoleGroupMember', params)
        },

        reset() {
            for (const key in this.dialogs) {
                this.dialogs[key].show = false
            }

            this.errors = {
                member: {
                    fields: {},
                },

                save: null,
            }

            this.member = JSON.parse(JSON.stringify(this.original_member))

            this.member_teams          = {}
            this.original_member_teams = {}

            this.team_uuid = null
        },

        showChangePasswordDialog() {
            this.dialogs.change_password.show = true
        },

        toggleMFA(checked) {
            if (checked) {
                this.showEnableMFADialog()
            } else {
                this.showDisableMFADialog()
            }
        },

        showEnableMFADialog() {
            this.dialogs.enable_mfa.show = true
        },
        onDialogEnableMFAConfirm() {
            this.dialogs.enable_mfa.show = false
        },
        onDialogEnableMFAClose() {
            this.dialogs.enable_mfa.show = false

            this.sign_in_with_otp = false
        },

        showDisableMFADialog() {
            this.dialogs.disable_mfa.show = true
        },
        onDialogDisableMFAConfirm() {
            this.dialogs.disable_mfa.show = false
        },
        onDialogDisableMFAClose() {
            this.dialogs.disable_mfa.show = false

            this.sign_in_with_otp = true
        },
    },

    computed: {
        ...mapGetters([
            'is_sp_admin',
            'is_super_admin',
            
            'current_spid',
            'teams',
            'identity_provider',

            'is_mfa_auth_toggler_enabled',
            'is_mfa_used',
            'id_auth_id',
        ]),

        show_mfa_auth_toggler() {
            return this.is_mfa_auth_toggler_enabled && this.member_auth_id == this.id_auth_id
        },

        member_auth_id() {
            return this.authId || this.$route.params.auth_id
        },

        fullname() {
            const fullname = [
                this.original_member.FirstName,
                this.original_member.LastName,
            ].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        is_loading() {
            let is_loading = false

            for (const key in this.loading) {
                is_loading = is_loading || this.loading[key]

                if (is_loading) {
                    break
                }
            }

            return is_loading
        },

        is_changed() {
            return this.is_member_changed || this.is_teams_changed
        },

        is_member_changed() {
            let is_member_changed = false

            for (const key in EMPTY_MEMBER) {
                is_member_changed = this.member[key] != this.original_member[key]

                if (is_member_changed) {
                    break
                }
            }

            return is_member_changed
        },

        is_teams_changed() {
            return Object.keys(this.teams_ready_to_be_added).length > 0 || Object.keys(this.teams_ready_to_be_deleted).length > 0
        },

        teams_ready_to_be_added() {
            const teams_ready_to_be_added = {}

            for (const key in this.member_teams) {
                if (!(key in this.original_member_teams)) {
                    teams_ready_to_be_added[key] = this.member_teams[key]
                }
            }

            return teams_ready_to_be_added
        },

        teams_ready_to_be_deleted() {
            const teams_ready_to_be_deleted = {}

            for (const key in this.original_member_teams) {
                if (!(key in this.member_teams)) {
                    teams_ready_to_be_deleted[key] = this.original_member_teams[key]
                }
            }

            return teams_ready_to_be_deleted
        },

        is_member_teams_available() {
            return Object.keys(this.member_teams).length > 0
        },

        show_remove_team_button() {
            return Object.keys(this.member_teams).length > 1
        },

        teams_for_adding() {
            const teams_for_adding = { ...this.teams }

            for (const key in this.member_teams) {
                delete teams_for_adding[key]
            }

            return teams_for_adding
        },

        is_teams_for_adding_available() {
            return Object.keys(this.teams_for_adding).length > 0
        },

        back_to() {
            return !this.on_sidebar && this.referrer && this.referrer.name == 'team'
                ? {
                    route: this.referrer,
                    title: 'Team',
                } : {
                    route: { name: 'members' },
                    title: 'Members',
                }
        },
    },

    watch: {
        member_auth_id() {
            this.reset()

            if (this.member_auth_id) {
                this.getMemberInfo()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => {
            route.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-member {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        margin-bottom: 48px;
    }

    .container {
        & > .row {
            & > .col-mob-12 {
                .app-error {
                    margin-top: 24px;
                }
            }
        }
    }

    .board {
        position: relative;
        padding: 24px;
        border-radius: $border-radius-primary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-secondary);
    }

    .personal {
        .app-input,
        .app-switch,
        .btn-change-password {
            margin: 24px 0 0;
        }
    }

    .teams {
        margin-top: 30px;

        ul {
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 0;
                border-bottom: 1px solid var(--color-divider);

                .team-name {
                    flex-grow: 1;

                    @include text-overflow();
                }

                .actions {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-shrink: 0;

                    margin-left: 8px;

                    .btn {
                        margin-left: 16px;

                        &:first-child { margin: 0; }
                    }

                    &:empty {
                        display: none;
                    }
                }
            }

            &:last-child {
                li {
                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            &:empty {
                display: none;
            }

            &+form {
                margin-top: 30px;
            }
        }

        form {
            position: relative;
            margin-top: 24px;

            .app-dropdown-select {
                margin-bottom: 24px;
            }
        }
    }

    .btn-full-width {
        margin-top: 24px;
    }

    &.on-sidebar {
        .header-info {
            h2 {
                font-size: 32px;
                line-height: 40px;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    #page-member {
        .board {
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-member {
        margin: 24px 0 64px;

        .btn-back-to {
            margin-bottom: 8px;
            font-size: 16px;
            line-height: 18px;
        }

        .header-info {
            margin-bottom: 32px;
        }

        .board {
            padding: 16px 8px;
            
            h3 {
                padding: 0;
            }
        }

        .teams {
            font-size: 14px;

            h3 {
                margin-bottom: 16px;
            }

            ul {
                li {
                    padding: 8px 0;

                    .actions {
                        .btn {
                            margin-left: 8px;
                        }
                    }
                }

                &+form {
                    margin-top: 24px;
                }
            }

            form {
                .app-dropdown-select {
                    margin-bottom: 24px;
                }
            }

            .btn {
                width: 100%;
                max-width: none;
            }
        }
    }
}
</style>